
$enable-rounded: false;
$mobile-breakpoint: 768px;

// $font-heading: 'Merriweather', serif;
// $font-body: 'Montserrat', sans-serif;
// $font-heading: 'Quicksand', serif;
$font-body: 'Quicksand', sans-serif;
$font-heading: 'breulgroteska-light', sans-serif;
//$font-body: 'breulgroteska-light', sans-serif;
$fa-font-path:   '../fonts';

$primary: #4e4e4e;
$primary-text: #2b2b2b;
$footer-text: #797979;

$white: #fff;
$light-grey: #efefef;
$grey: #ddd;
$black: #000;

$form-border-color: #ced4da;
$white-overlay: rgba(255, 255, 255, .75);
$drop-shadow-dark: rgba(0, 0, 0, .25);
$drop-shadow-light: rgba(255, 255, 255, .3);
