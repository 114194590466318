@import 'variables';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/fontawesome';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/brands';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/solid';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/regular';
@import 'node_modules/@fortawesome/fontawesome-pro/scss/light';
@import 'node_modules/slick-carousel/slick/slick.scss';


$enable-rounded: false;
$mobile-breakpoint: 768px;

// $font-heading: 'Merriweather', serif;
// $font-body: 'Montserrat', sans-serif;
// $font-heading: 'Quicksand', serif;
$font-body: 'Quicksand', sans-serif;
$font-heading: 'breulgroteska-light', sans-serif;
//$font-body: 'breulgroteska-light', sans-serif;
$fa-font-path:   '../fonts';

$primary: #4e4e4e;
$primary-text: #2b2b2b;
$footer-text: #797979;

$white: #fff;
$light-grey: #efefef;
$grey: #ddd;
$black: #000;

$form-border-color: #ced4da;
$white-overlay: rgba(255, 255, 255, .75);
$drop-shadow-dark: rgba(0, 0, 0, .25);
$drop-shadow-light: rgba(255, 255, 255, .3);

.debugbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-height: 360px;

  .debugbar-detail {
    margin-top: 8px;
    height: 300px;
    overflow-y: scroll;
  }
}

.form-control {
  &::placeholder {
    color: $form-border-color;
  }
}

.input-group-text {
  justify-content: center;
  min-width: 48px;
}

.textarea-wrapper {
  .input-group-text {
    align-items: flex-start;
    padding-top: .75em;
  }
}

.btn-mega {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  padding: 1rem;
  min-height: 64px;

  i {
    margin-right: 1rem;
  }

  img {
    margin: 0 1rem 0 -1rem;
    width: 62px;
    height: 62px;
  }

  .icon-spacer {
    display: inline-block;
  }
}

.form-button-outline {
  border-color: $form-border-color;
}

.custom-file-label {
  top: -1px;
}

.confirm-delete-item,
.confirm-delete-profile,
.confirm-delete-user {
  transition: width .3s ease-out;
  padding: .375rem .75rem;
  width: 122px;

  &.hide {
    border-right: 0;
    border-left: 0;
    padding: .375rem 0;
    width: 0;
    font-size: 0;
  }
}

.input-group-append {
  .btn {
    + .confirm-delete-item {
      &.hide {
        margin-left: 0;
      }
    }
 }
}

.page-login {
  .page-wrapper {
    height: 100%;
  }
}

.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $dark;
  background-image: linear-gradient(180deg, $dark 0%, $dark 50%, $light 50%, $light 100%);
  width: 100%;
  height: 100%;

  @media(min-width: $mobile-breakpoint) {
    background-image: linear-gradient(90deg, $dark 0%, $dark 50%, $light 50%, $light 100%);
  }
}

.login-logo {
  max-width: 120px;
}

html,
body {
  height: 100%;
}

body {
  overflow-x: hidden;
}

.spacer {
  height: 72px;
}

.splash-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  img {
    max-width: 300px;
  }
}



.w-80p {
  width: 80%;
}

.w-60p {
  width: 60%;
}

.w-40p {
  width: 40%;
}

.middle-left-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
}

.navbar-brand {
  max-width: 70px;
}

.auth-menu {
  margin-left: 1em;
  max-width: 200px;
}

.layout-toggle {
  margin: 0 0 0 16px;
}

.card-img-wrapper {
  background-color: $light-grey;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.image-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  min-height: 200px;
  color: $grey;

  img {
    border: 1px solid $drop-shadow-light;
    border-radius: 10%;
    box-shadow: 12px 12px 16px 0 $drop-shadow-dark, -8px -8px 12px 0 $drop-shadow-light;
    max-height: 150px;
  }
}

.card-name {
  background-color: $grey;
  text-align: center;
  color: $footer-text;
}

.placeholder-content {
  display: inline-block;
  background-color: $light-grey;
  height: 16px;
}

.add-profile-placeholder {
  a {
    text-decoration: none;
  }
}

.profile-header {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 128px;
  padding-bottom: 48px;
  text-align: center;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $white-overlay;
    width: 100%;
    height: 100%;
    content: '';
  }
}

.profile-photo {
  margin-bottom: 2em;

  @media(min-width: $mobile-breakpoint) {
    margin-bottom: 0;
  }

  img {
    max-height: 300px;
  }
}

.profile-title-wrapper {
  @media(min-width: $mobile-breakpoint) {
    display: flex;
    align-items: flex-end;
  }
}

.profile-body {
  padding: 48px 0;
}

.profile-company {
  margin-bottom: 0;
}

.profile-logo {
  margin-right: 16px;
  max-height: 50px;
}

.profile-footer {
  color: $footer-text;
}

.vcard-info,
.vcard-contacts,
.vcard-addresses,
.vcard-links {
  margin-bottom: .75em;

  .att-label,
  .att-value {
    margin-bottom: .25em;
  }
}

@media(max-width: 576px) {
  .vcard-addresses {
    .input-group {
      >input {
        width: 100% !important;
      }
    }
  }
}

.profile-edit {
  .vcard-info,
  .vcard-profile,
  .vcard-contacts,
  .vcard-addresses,
  .vcard-links {
    margin-bottom: 5em;
  }
}

.card-download-tile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
}

.page-wrapper {
  .sidebar-wrapper {
    &.open {
      margin-left: -165px;

      @media (min-width: $mobile-breakpoint) {
        margin-left: 0;
      }
    }
  }
}

.sidebar-wrapper {
  transition: margin .25s ease-out;
  margin-left: 0;
  width: 250px;
  min-height: 100vh;


  @media (min-width: $mobile-breakpoint) {
    margin-left: -185px;
  }

  .sidebar-heading {
    padding: 16px 64px 16px 24px;
    font-size: 1.2rem;
  }

  .sidebar-logo {
    img {
      margin: 64px 0;
      max-width: 100px;
    }
  }

  .list-group {
    padding: 16px 56px 16px 0;

    .list-group-item {
      background-color: transparent;
      color: $white;

      &:hover {
        background-color: $drop-shadow-light;
      }

      &.no-hover {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.sidebar-toggle {
  position: fixed;
  bottom: 56px;
  left: 0;
  margin: 8px;

  i {
    transform: rotate(0deg);
    transition: transform .25s ease-out;
  }

  &.open {
    i {
      transform: rotate(180deg);
    }
  }
}

.content-wrapper {
  width: 100%;
}


.skin-2 {

  h1,
  .h1 {
    font-size: 1.8rem;
    @media(min-width: $mobile-breakpoint) {
      font-size: 2rem;
    }
  }

  h2,
  .h2 {
    font-size: 1.6rem;
    @media(min-width: $mobile-breakpoint) {
      font-size: 1.8rem;
    }
  }

  h3,
  .h3 {
    font-size: 1.4rem;
    @media(min-width: $mobile-breakpoint) {
      font-size: 1.6rem;
    }
  }

  h4,
  .h4 {
    font-size: 1.2rem;
    @media(min-width: $mobile-breakpoint) {
      font-size: 1.4rem;
    }
  }

  h5,
  .h5 {
    font-size: 1.2rem;
  }

  h6,
  .h6 {
    font-size: 1rem;
  }

  p {
    margin-bottom: 0;
  }

  a {
    &.text-primary {
      &:hover,
      &:focus {
        color: $primary !important;
      }
    }
  }

  .card-img-wrapper {
    // background-color: rgba($primary, 1);
    background-image: none !important;

    .image-placeholder {
      // background-color: $primary;
    }
  }

  .card-body-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }


  .profile-header {
    padding-top: 36px;
    padding-bottom: 24px;
    color: $primary-text;
  }

  .profile-body {
    padding: 0 0 36px;
  }

  .image-placeholder {
    img {
      border: 5px solid $primary;
      border-radius: 50%;
      width: 200px;
      max-width: 200px;
      height: 200px;
      max-height: 200px;
    }
  }

  &.page-p {
    .image-placeholder {
      margin-bottom: 36px;
      padding: 0 1rem;
    }
  }

  &.page-profiles {
    .image-placeholder {
      img {
        border: 1px solid $light-grey;
        width: 160px;
        max-width: 160px;
        height: 160px;
        max-height: 160px;
      }
    }
  }

  .profile-name {
    margin-bottom: 0;
  }

  .profile-company {
    font-weight: 400;
  }

  .profile-position {
    font-weight: 400;
  }

  .btn {
    .button-label {
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .btn-dark {
    background-color: $primary;
    color: $white;

    &:hover {
      background-color: $primary-text;
    }

    .button-label {
      overflow: hidden;
      white-space: nowrap;
    }
  }


  .btn-mega {
    justify-content: space-between;
    border-radius: 8px;
    font-size: 1.1em;
    word-break: break-word;

    @media(min-width: $mobile-breakpoint) {
      font-size: 1.3rem;
    }

    .icon-spacer {
      width: auto;

      @media(min-width: $mobile-breakpoint) {
        width: 52px;
      }
    }
  }

}

.toast-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  width: 300px;
}


.body-sql,
.body-debug {
  font-family: monospace;
  font-size: 12px;
}

@font-face {
  font-family: 'breulgroteska-light';
  src: url('/fonts/breulgroteska-light-webfont.woff2') format('woff2'),
       url('/fonts/breulgroteska-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: $font-body;
  font-size: 16px;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  // text-transform: uppercase;
  font-family: $font-heading;
  font-weight: 700;
}

h1,
.h1 {
  // font-size: 36px;
  // @media(min-width: $mobile-breakpoint) {
  //   font-size: 48px;
  // }
}

h2,
.h2 {
  // font-size: 36px;
  // @media(min-width: $mobile-breakpoint) {
  //   font-size: 48px;
  // }
}

h3,
.h3 {
  // font-size: 32px;
  // @media(min-width: $mobile-breakpoint) {
  //   font-size: 36px;
  // }
}

h4,
.h4 {
  // font-size: 28px;
  // @media(min-width: $mobile-breakpoint) {
  //   font-size: 32px;
  // }
}

h5,
.h5 {
  // font-size: 24px;
}

h6,
.h6 {
  // font-size: 18px;
}

// .btn {
//   font-size: 22px;
//   @media(min-width: $mobile-breakpoint) {
//     font-size: 32px;
//   }
// }
//
// strong {
//   font-weight: 700;
// }
